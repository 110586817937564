<template>
  <module-container
    :title="$t('debt.collection')"
    dense
    hide-new-button
    route-name="invoices.debt"
    show-toolbar
  >
    <template #route>
      <invoices-list
        :hide-headers="['is_cash']"
        :invoice-type="[701, 901]"
        base-route="invoices.debt"
        filter-key="debt_emitted"
        signed
      />
    </template>

    <template #filters>
      <invoice-filters filter-key="debt_emitted" />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoicesList from "@/modules/invoices/components/InvoicesList.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";
import InvoiceFilters from "@/modules/invoices/components/InvoiceFilters.vue";

@Component({
  components: { InvoiceFilters, InvoicesList },
})
export default class InvoicesDebtEmitted extends Mixins(
  PaginateProviderMixin
) {}
</script>
